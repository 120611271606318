import React, { useEffect, useRef, useState } from 'react';

import Image from 'Components/common/image';

import { PlaySvg } from 'Assets/commonSvgs';

import { trackEvent } from 'Utils/analytics';
import PlatformUtils from 'Utils/platformUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { DEFAULT_IMAGE_DENSITY } from 'Constants/constants';

import Conditional from '../conditional';

import { PlayButton, StyledVideo, VideoContainer } from './style';

type ImageObj = {
	url?: string | null;
	altText?: string;
	title?: string;
};

export interface VideoTypeProps {
	url?: string;
	fallbackImage?: ImageObj;
	isMuted?: boolean;
	shouldAutoPlay?: boolean;
	isLooped?: boolean;
	width?: number;
	height?: number;
	currentlyPlaying?: boolean;
	isBanner?: boolean;
	responsive?: boolean;
	eventTracking?: boolean;
	useVideoPoster?: boolean;
	aria?: {
		labelledBy?: string;
		label?: string;
	};
	preventVideoClickEventPropagation?: boolean;
	imageDensity?: number;
	priortizeImage?: boolean;
}

let isDesktop = PlatformUtils.isDesktop();
let autoPlayTracked = false;
// @ts-expect-error TS(7034): Variable 'appMountTime' implicitly has type 'any' ... Remove this comment to see the full error message
let appMountTime = null;
const Video: React.FC<React.PropsWithChildren<VideoTypeProps>> = ({
	url,
	fallbackImage,
	isLooped = true,
	isMuted = true,
	shouldAutoPlay = true,
	currentlyPlaying = true /* relevant only when autoplay is false */,
	width,
	height,
	isBanner,
	responsive,
	eventTracking,
	aria,
	useVideoPoster = false,
	preventVideoClickEventPropagation = true,
	imageDensity = DEFAULT_IMAGE_DENSITY,
	priortizeImage = false,
}) => {
	// @ts-expect-error TS(2352): Conversion of type 'null' to type 'HTMLVideoElemen... Remove this comment to see the full error message
	const videoRef = useRef(null as HTMLVideoElement);
	const [isAutoplayDisabled, setIsAutoplayDisabled] = useState(false);
	const [isPaused, setIsPaused] = useState(true);
	const [videoLoaded, setVideoLoaded] = useState(false);

	useEffect(() => {
		appMountTime = new Date().getTime();
	}, []);

	useEffect(() => {
		if (videoLoaded) return;
		if (!videoRef.current) return;
		const videoElement = videoRef.current;
		const videoSource = videoElement.children[0] as HTMLSourceElement;
		// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
		videoSource.src = videoSource.dataset.src;
		videoElement.load();

		const trackAutoPlay = () => {
			if (autoPlayTracked && !isAutoplayDisabled) return;
			autoPlayTracked = true;

			const videoStartTime = new Date().getTime();
			// @ts-expect-error TS(7005): Variable 'appMountTime' implicitly has an 'any' ty... Remove this comment to see the full error message
			const videoAutoPlayTime = videoStartTime - appMountTime;

			eventTracking &&
				trackEvent({
					eventName: ANALYTICS_EVENTS.VIDEO.AUTOPLAY_STARTED,
					[ANALYTICS_PROPERTIES.AUTOPLAY_LOAD_TIME]:
						videoAutoPlayTime / 1000,
				});
		};

		const startVideoAutoPlay = async (e: any) => {
			setVideoLoaded(true);

			try {
				if (currentlyPlaying) {
					await e.target.play();
					setIsPaused(false);
					setIsAutoplayDisabled(false);
					trackAutoPlay();
				}
			} catch (error) {
				setIsAutoplayDisabled(true);
				setIsPaused(true);
				eventTracking &&
					trackEvent({
						eventName: ANALYTICS_EVENTS.VIDEO.AUTOPLAY_FAILED,
					});
			}
		};

		videoElement.addEventListener('loadeddata', startVideoAutoPlay);
		return () =>
			videoElement.removeEventListener('loadeddata', startVideoAutoPlay);
	}, [currentlyPlaying, eventTracking, isAutoplayDisabled, videoLoaded]);

	useEffect(() => {
		if (!videoLoaded) return;

		if (currentlyPlaying) {
			videoRef.current
				.play()
				.then(() => {
					setIsPaused(false);
				})
				.catch(() => {
					setIsPaused(true);
				});

			return;
		}

		videoRef.current.pause();
		setIsPaused(true);
	}, [currentlyPlaying, videoLoaded]);

	const handleVideoClick = (e: any) => {
		if (preventVideoClickEventPropagation) {
			e.stopPropagation();
		}
		videoRef.current.play();

		if (isPaused) {
			setIsPaused(false);
			eventTracking &&
				trackEvent({
					eventName: ANALYTICS_EVENTS.VIDEO.PLAYED,
					[ANALYTICS_PROPERTIES.POSITION]: isBanner
						? ANALYTICS_PROPERTIES.BANNER
						: ANALYTICS_PROPERTIES.PRODUCT_CARD,
				});
		} else {
			eventTracking &&
				trackEvent({
					eventName: ANALYTICS_EVENTS.VIDEO.DEAD_CLICK,
				});
		}
	};

	const { url: fallbackImageUrl, altText: imageAltText } =
		fallbackImage ?? {};

	return (
		// @ts-expect-error TS(2769): No overload matches this call.
		<VideoContainer
			className={'video-container'}
			$fadeInVideo={videoLoaded}
			$isDesktop={isDesktop}
			width={width}
			height={height}
			$responsive={responsive}
		>
			<Conditional if={!!fallbackImage && !useVideoPoster}>
				<Image
					// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
					src={fallbackImageUrl}
					width={width}
					height={height}
					alt={imageAltText || ''}
					onClick={handleVideoClick}
					layout={responsive ? undefined : 'responsive'}
					priority={priortizeImage}
					density={imageDensity}
				/>
			</Conditional>
			<Conditional if={isAutoplayDisabled && isPaused}>
				<PlayButton onClick={handleVideoClick}>
					<PlaySvg />
				</PlayButton>
			</Conditional>
			<Conditional if={url}>
				{/* @ts-expect-error TS(2769): No overload matches this call. */}
				<StyledVideo
					ref={videoRef}
					autoPlay={shouldAutoPlay}
					muted={isMuted}
					playsInline
					disablePictureInPicture
					onClick={handleVideoClick}
					loop={isLooped}
					width={width}
					height={height}
					poster={useVideoPoster ? fallbackImageUrl : undefined}
					$responsive={responsive}
					aria-label={aria?.label}
					aria-labelledby={aria?.labelledBy}
				>
					<source data-src={url} type={'video/mp4'} />
				</StyledVideo>
			</Conditional>
		</VideoContainer>
	);
};

export default Video;
